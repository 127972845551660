<template>
  <div
    data-app
    :class="{
      'date-picker': true,
      'w-100 px-3 mb-1': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      :close-on-click="true"
      transition="scale-transition"
      class="date-picker"
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{}">
        <v-text-field
          :value="value"
          class="date-picker-text-field mr-md-1"
          dense
          outlined
          height="30px"
          :placeholder="placeholder"
          @input="$emit('input', $event)"
          @click-on-append-icon="showDatePicker = true"
        >
          <template #append>
            <v-btn
              class="date-picker__button"
              small
              icon
              @click="showDatePicker = true"
            >
              <v-icon size="22">
                mdi-calendar-range
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        :value="value"
        :first-day-of-week="7"
        locale="en"
        no-title
        @input="
          $emit('input', $event);
          showDatePicker = false;
        "
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    placeholder: {
      default: () => "Choose a date",
      type: String,
    },
    value: {
      default: () => {},
    },
  },
  data() {
    return {
      showDatePicker: false,
    };
  },
  computed: {
    datePicked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.showDatePicker = false;
      },
    },
  },

  methods: {
    onInputDate(date) {
      if (date.isValid()) {
        this.$emit("input", date);
      } else {
        this.$emit("input", null);
        this.$refs["input"]["model"] = null;
      }

      this.showDatePicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  &__button {
    margin-top: -2px;
  }
}

.date-picker-text-field {
  .v-input__slot {
    width: 150px;
  }
}
.date-picker {
  .v-text-field {
    .v-input__slot {
      width: 100%;
      min-height: 30px !important;
      border-radius: 4px;
      border: solid 1px #dee7ee !important;
      background-color: #ecf1f5 !important;
      margin-bottom: 0;
      padding: 0 0 0 8px !important;
      fieldset {
        border-width: 0;
      }
      input {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #66788e;
      }
    }
    input {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    .v-input__append-inner {
      margin-top: 3px !important;
      button {
        font-size: 16px;
      }
    }
  }
}
</style>
